import workflowServices from '../../../../services/work-flow'
import store from '../../../../store'
export default {
    components: {

    },
    data() {
        return {
            title: '',
            formType: '',
            item: [],
            communicationId: null,
            orgType: store.state.userType,
            providerCommunicationId: null,
            communicationType: ''
        }
    },

    methods: {
        forceRerenderView(item,communicationId,type,providerCommunicationId,communicationType) {
            this.communicationId=communicationId
            this.providerCommunicationId=providerCommunicationId
            this.item = item
            this.communicationType = communicationType
            this.title = 'Delegate Reminder'
            this.formType = type
            this.$refs.modal.show()
        },
        cancel() {
            this.$refs.modal.hide()
        },
        save()
        {
            workflowServices.ResendDelegateMail(this.communicationId, this.item !=null?this.item.delegate_id:0,(this.orgType == 'PROVIDER' ? (this.providerCommunicationId == undefined ? this.$route.params.providerCommunicationId : this.providerCommunicationId) : 0),this.communicationType).then(resp => {
                if (!resp.error) {
                    this._showToast('Email sent successfully!')
                    this.$refs.modal.hide()
                    this.$parent.FetchEmailList()
                }
            })
        }
    }

}