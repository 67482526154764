import recallServices from '../../../../../services/provider/recall'
import recallNoteService from '../../../../../services/provider/recall-notes'
import DocumentViewModel from '../../../../../components/part/shared/DocumentViewModel'
import { VueEditor } from 'vue2-editor'
import { COMMUNICATION_TYPE, PROVIDERREPORTS } from '../../../../../misc/commons'
import workflowServices from '../../../../../services/work-flow'
export default {
    props: {
        communicationId: { type: String, default: null },
        providerCommunicationId: {type: String, default: ''},
        supplierRecallId: {type: String, default: ''},
        communicationType: {type: String, default: ''},
    },
    components: {
        VueEditor,
        DocumentViewModel,
    },
    data() {
        return {
            histories: [],
            item1: '',
            activeIndex: 0,
            recall: COMMUNICATION_TYPE.RECALL,
            reports: PROVIDERREPORTS,
            filteredReports: [],
        }
    },
    created() {
        workflowServices.getCommunicationSummaryById(this.communicationId,this.providerCommunicationId,this.communicationType).then((resp) => {
            this.item1 = resp.data.d
            this.filterReports()
            this.getReportHistory(this.filteredReports[0].value)
        })
    },
    methods: {
        filterReports() {
            if (this.item1) {
                if (this.item1.isProviderCreated) {
                    this.filteredReports = this.reports.filter(report => report.value === 'Affected Products' || report.value === 'Change History' || report.value === 'Delegate Status')
                } else if (this.item1.is_customized_content) {
                    this.filteredReports = this.reports.filter(report => report.value === 'Customized Content' || report.value === 'Change History' || report.value === 'Delegate Status')
                } else {
                    this.filteredReports = this.reports.filter(report => report.value === 'Affected Locations' || report.value === 'Affected Products' || report.value === 'Change History' || report.value === 'Delegate Status')
                }
            }
        },
        getReportHistory(selectedReport) {
            recallServices.GetReportHistories(this.communicationId, this.providerCommunicationId,selectedReport).then(resp => {
                if (!resp.error) {
                    this.histories = resp.data.d
                }
            })
        },
        addReportDownloadHistory(selectedReport) {
            recallServices.AddReportDownloadHistory(this.communicationId, this.providerCommunicationId, selectedReport).then(resp => {
                if (!resp.error) {
                    this.getReportHistory(selectedReport)
                }
            })
        },
        activateCol(index, val) {
            this.activeIndex = index
            this.getReportHistory(val)
        },
        isActive(index) {
            return this.activeIndex === index
        },
        downloadCSVReport(val){
            if (val == 'Affected Locations') {
                this.downloadproviderAffectedLocationReport('csv')
            }
            else if (val == 'Affected Products') {
                this.downloadproviderAffectedProductsReport('csv')
            }
            else if (val == 'Customized Content'){
                this.downloadCCReport('csv')
            }
            else if (val == 'Delegate Status'){
                this.exportDelegateStatusReportCSV()
            }
        },
        downloadXLSXReport(val){
            if (val == 'Affected Locations') {
                this.downloadproviderAffectedLocationReport('xlxs')
            }
            else if (val == 'Affected Products') {
                this.downloadproviderAffectedProductsReport('xlxs')
            }
            else if (val == 'Customized Content') {
                this.downloadCCReport('xlxs')
            }
            else if (val == 'Delegate Status'){
                this.downloadDelegateStatusReport()
            }
            else if (val == 'Change History') {
                this.downloadChangeHistoryXLSXReport()
            }
        },
        downloadproviderAffectedLocationReport(extensionType) {
            recallServices.getRecallById(this.communicationId,this.providerCommunicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'prov_Recall' })
                } else {
                    var isCentralizedSettingOn = this.$store.state?.organization?.is_centralized_setting_enabled
                    recallServices.downloadproviderAffectedLocationReport(this.communicationId,isCentralizedSettingOn  ? 'EMAILED' :'COVERED',this.$store.state.user.id ? this.$store.state.user.id : -1,extensionType,resp.data.d.communicationUID,this.providerCommunicationId,resp.data.d.recallsitename)
                    this.addReportDownloadHistory('Affected Locations')
                }
            })
        },
        downloadproviderAffectedProductsReport(extensionType) {
            recallServices.getRecallById(this.communicationId,this.providerCommunicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'prov_Recall' })
                } else {
                    recallServices.downloadproviderAffectedProductsReport(this.communicationId,extensionType,resp.data.d.communicationUID,this.providerCommunicationId,resp.data.d.recallsitename)
                    this.addReportDownloadHistory('Affected Products')
                }
            })
        },
        downloadCCReport(extensionType) {
            recallServices.getRecallById(this.communicationId,this.providerCommunicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'prov_Recall' })
                } else {
                    recallServices.downloadCCReport(this.communicationId,extensionType,resp.data.d.communicationUID,this.providerCommunicationId,resp.data.d.recallsitename)
                    this.addReportDownloadHistory('Customized Content')
                }
            })
        },
        downloadDelegateStatusReport() {
            workflowServices
                .downloadProviderDelegateStausReport(this.communicationId, this.item1.communicationUID,this.providerCommunicationId,this.item1.recallsitename,this.communicationType,'xlsx')
                .then((resp) => {
                    this.addReportDownloadHistory('Delegate Status')
                })
        },
        exportDelegateStatusReportCSV() {
            workflowServices
                .downloadProviderDelegateStausReport(this.communicationId, this.item1.communicationUID,this.providerCommunicationId,this.item1.recallsitename,this.communicationType,'csv')
                .then((resp) => {
                    this.addReportDownloadHistory('Delegate Status')
                })
        },
        downloadChangeHistoryXLSXReport() {
            recallServices.downloadChangeHistoryXLSXReport(this.communicationId, this.providerCommunicationId, this.supplierRecallId).then(resp => {
                this.addReportDownloadHistory('Change History')
            })
        },
    }
}