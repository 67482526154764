import recallServices from '../../../../../services/provider/recall'
import recallNoteService from '../../../../../services/provider/recall-notes'
import DocumentViewModel from '../../../../../components/part/shared/DocumentViewModel'
import { VueEditor } from 'vue2-editor'
import { COMMUNICATION_TYPE } from '../../../../../misc/commons'
export default {
    props: {
        communicationId: { type: String, default: null },
        providerCommunicationId: {type: String, default: ''},
        supplierRecallId: {type: String, default: ''},
        communicationType: {type: String, default: ''},
    },
    components: {
        VueEditor,
        DocumentViewModel,
    },
    data() {
        return {
            histories: [],
            showHistory: false,
            showNotes: true,
            filesUpload: [],
            recallNotesList: [],
            showdetails: false,
            isEditNote: false,
            selectednote: 0,
            recall: COMMUNICATION_TYPE.RECALL,
            noteDetails: {
                id: null,
                subject: null,
                bodyContent: null,
                created_at: null,
                created_by: null,
                is_view_only: false,
                last_updated: null,
            }
        }
    },
    created() {
        this.GetRecallHistories()
        this.$emit('onReLoadCommunicationData')
        this.findCommunicationNote()
        this.UpdateRecallHistories()
        if (this.$route.query.tabName ==='tabHistory' || (this.$route.query.logtype && this.$route.query.logtype !='')) {
            this.$store.state.lastedRouteNotAuth=''
            this.showNotes = false
            this.showHistory = true
        }
    },
    methods: {
        GetRecallHistories() {
            recallServices.GetRecallHistories(this.communicationId, this.providerCommunicationId).then(resp => {
                if (!resp.error) {
                    this.histories = resp.data.d
                }
            })
        },
        UpdateRecallHistories() {
            recallServices.UpdateRecallHistories(this.communicationId, this.providerCommunicationId)
        },
        findCommunicationNote() {
            recallNoteService.findCommunicationNote(this.communicationId,this.providerCommunicationId).then(resp => {
                if (!resp.error) {
                    this.recallNotesList = resp.data.d
                }
            })
        },
        uploadAttachmentFile(files) {
            this.filesUpload.push({
                name: files.currentTarget.files[0].name,
                size: files.currentTarget.files[0].size,
                type: files.currentTarget.files[0].type,
                file: files.currentTarget.files[0],
            })
        },
        getNoteDetails(id) {

            this.showdetails = true
            this.noteDetails=null
            this.filesUpload=[]
            this.selectednote=id
            recallNoteService.getCommunicationNote(id).then(res => {
                if (!res.error) {
                    this.noteDetails = res.data.d
                    this.filesUpload = res.data.d.documents
                }
            })
        },
        editNote() {
            this.showdetails = false
            this.showNotes = true
        },
        removeDocument(index) {
            this.filesUpload.splice(index, 1)
        },
        LoadNotes() {
            this.selectednote=null
            this.showNotes = true
            this.showdetails = false
            this.showHistory = false
            this.noteDetails = {}
            this.filesUpload=[]
        },
        LoadChangeHistory() {
            this.showNotes = false
            this.showHistory = true
            this.showdetails = false
            this.noteDetails = {}
            this.filesUpload=[]
            this.UpdateRecallHistories()
            this.GetRecallHistories()
            if (this.$parent.$parent.$parent.$refs.updatenoti!=undefined && this.$parent.$parent.$parent.$refs.updatenoti.textContent != undefined) {
                var nt = parseInt(this.$parent.$parent.$parent.$refs.updatenoti.textContent)
                if(nt > 0 & nt != 0)
                {
                    this.$parent.$parent.$parent.updateNotificationCount = 0
                }
            }
        },
        saveNote() {
            var isValid = this.$refs['observer'].validate()
            if (isValid) {
                var html = ''
                if (this.noteDetails.bodyContent != null) {
                    for (let p = 0; p < this.$refs['emailbody'].$refs.quillContainer.children[0].children.length; p++) {
                        if (this.$refs['emailbody'].$refs.quillContainer.children[0].children[p].innerHTML == '<br>') {
                            html += '<span>' + this.$refs['emailbody'].$refs.quillContainer.children[0].children[p].innerHTML + '</span>'
                        }
                        else {
                            html += '<span>' + '<br>' + this.$refs['emailbody'].$refs.quillContainer.children[0].children[p].innerHTML + '</span>'
                        }
                    }
                    html = html.replace('<p><br></p>', '')
                }
                let formData = new FormData()
                formData.append('subject', this.noteDetails.subject)
                formData.append('recallNoteId', this.noteDetails.id ? this.noteDetails.id : 0)
                formData.append('body', html)
                this.filesUpload.forEach((x, i) => {
                    if (!x.id) {
                        formData.append('document', x.file)
                    }
                    formData.append('documentInfo[' + i + '].recallNoteDocumentId', x.id ? x.id : 0)
                })

                recallNoteService.saveCommunicationNote(this.communicationId, this.communicationType, this.providerCommunicationId, formData).then(res => {
                    if (!res.error) {
                        this.findCommunicationNote()
                        this.getNoteDetails(res.data.d)
                        if (res.data.d > 0) {
                            this._showToast(this.communicationType == COMMUNICATION_TYPE.RECALL ? 'Recall note updated successfully.' : 'Advisory note updated successfully.')
                        } else {
                            this._showToast(this.communicationType == COMMUNICATION_TYPE.RECALL ? 'Recall note Added Successfully.' : 'Advisory note Added Successfully.')
                        }
                    }
                })
            }
        },
        downloadChangeHistoryXLSXReport() {
            recallServices.downloadChangeHistoryXLSXReport(this.communicationId, this.providerCommunicationId, this.supplierRecallId).then(resp => {
                if (resp.error) {
                    this._showToast('Recall change history download failed.', { variant: 'danger' })
                }
            })
        },
    }
}