import { http, param } from '../utils/http'
import conf from '../config'
import fileDownload from 'js-file-download'
export const API_ROOT = `${conf.API_URL}`

const workflowServices = {
    async getCommunicationSummaryById(id, providerCommunicationId, communicationType) {
        return http.get(`${API_ROOT}/workflow/${id}/summary/${providerCommunicationId??0}/${communicationType}`).then(resp => {
            return resp
        })
    },
    async getRecallAction_Async(id, providerCommunicationId, communicationType) {
        return http.get(`${API_ROOT}/workflow/${id}/delegate/${communicationType}/actions/${providerCommunicationId??0}`).then(resp => {
            return resp
        })
    },
    async getEmail(type,communicationType) {
        return http.get(`${API_ROOT}/workflow/getmailtype/${type}/${communicationType}`).then(resp => {
            return resp
        })
    },
    async ResendDelegateMail(id, delegateId, providerCommunicationId, communicationType) {
        return http.post(`${API_ROOT}/workflow/${id}/resendmail/${delegateId}/${providerCommunicationId??0}/${communicationType}`).then(resp => {
            return resp
        })
    },
    async submitDelegate(id, delegateId, payload, providerCommunicationId, communicationType) {
        return http.post(`${API_ROOT}/workflow/${id}/${delegateId}/${providerCommunicationId??0}/${communicationType}`, payload).then(resp => {
            return resp
        })
    },
    async getDomain(id, organizationId, communicationType) {
        return http.get(`${API_ROOT}/workflow/valid-domain/emailid/${id}/${organizationId}/${communicationType}`).then(resp => {
            return resp
        })
    },
    async getDelegateEmailList(organizationId, isincludedl = false, communicationType) {
        return http.get(`${API_ROOT}/workflow/${organizationId}/${isincludedl}/emaillist/${communicationType}`).then(resp => {
            return resp
        })
    },
    async getSupportigDoc(id, communicationType) {
        return http.get(`${API_ROOT}/workflow/${id}/supportingdoc/${communicationType}`).then(resp => {
            return resp
        })
    },
    async getProviderActionDetail(id, providerOrgId, providerCommunicationId, communicationType) {
        return http.get(`${API_ROOT}/workflow/${id}/${providerOrgId}/internalaction/${providerCommunicationId??0}/${communicationType}`).then(resp => {
            return resp
        })
    },
    async downloadProviderDelegateStausReport(id, communicationUID, providerCommunicationId, recallsitename, communicationType, reportType) {
        return http.get(`${API_ROOT}/workflow/${id}/actions/delegate-statusreport/${communicationType}/${providerCommunicationId??0}/${reportType}`, { responseType: 'blob' }).then(resp => {
            if (reportType == 'xlsx') {
                if (recallsitename != null && recallsitename != '') {
                    fileDownload(resp.data, 'Delegate Status Report_' + communicationUID + '_' + recallsitename + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                }
                else {
                    fileDownload(resp.data, 'Delegate Status Report_' + communicationUID + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                }
            }
            else if (reportType == 'csv') {
                if (recallsitename != null && recallsitename != '') {
                    fileDownload(resp.data, 'Delegate Status Report_' + communicationUID + '_' + recallsitename + '.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                }
                else {
                    fileDownload(resp.data, 'Delegate Status Report_' + communicationUID + '.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                }
            }
        })
    },
    async getRecallAction_DelegateListing_Detail(id, delegateId,communicationType) {
        return http.get(`${API_ROOT}/workflow/${id}/delegate/${communicationType}/listingdetails/${delegateId}`).then(resp => {
            return resp
        })
    },
    async getRecallAction_DelegateListing(id,providerCommunicationId,communicationType) {
        return http.get(`${API_ROOT}/workflow/${id}/delegate/${communicationType}/${providerCommunicationId??0}`).then(resp => {
            return resp
        })
    },
    async GetRecallAction_Detail (id,delegateId,actionId,providerCommunicationId,communicationType) {
        return http.get(`${API_ROOT}/workflow/${id}/${delegateId}/actions/${actionId}/${providerCommunicationId??0}/${communicationType}`).then(resp => {
            return resp
        })
    },
    async sendInfoMail(communicationId,communicationType,payload) {
        return http.post(`${API_ROOT}/workflow/supplierMail/${communicationId}/${communicationType}`, payload).then(resp => {
            return resp
        })
    },
    async addCustomActions (payload) {
        return http.post(`${API_ROOT}/workflow/custom/actions/step2`, payload).then(resp => {
            return resp
        })
    },
    async addCustomListActions (payload) {
        return http.post(`${API_ROOT}/workflow/custom/actions/list`, payload).then(resp => {
            return resp
        })
    },
}

export default workflowServices
