import VueTagsInput from '@johmun/vue-tags-input'
import recallServicesProv from '../../../services/provider/recall'
export default {
    components: {
        VueTagsInput
    },
    props: {
        CcEmails: {type: Object, default: []},
        communicationType: {type: String, default: ''},
        SupplierName: {type: String, default: ''},
        autonotifychecked: {type: Boolean, default: false},
        SupplierContactlst: {type: Array, default: []},
        allowResponseEmail: {type: Boolean, default: true},
        firstCompletedDatetime: {type: Date, default: null}
    },
    data() {
        return {
            item: {
                affectedProduct: '',
                affectedProductValue: ''
            },
            isSaveEnabled: false,
            defaultEmailList: [],
            response_email: [],
            filesUpload: [],
            sup: 'sup',
            placeholder: 'Email Id',
            disableEmailTagList: false,
            valueTag: [],
            enableValueTag: [],
            tags: [],
            valueTagE: [],
            tagsE: [],
            tagE: '',
            ccvalueE: [],
            tag: '',
            emailError: '',
            emailErrorE: '',
            ccvalue: [],
            options: [],
            autocompleteItems: [],
            originalValueTag: [],
        }
    },
    computed: {
        form() {
            if(this.communicationType == 'RECALL')
            {
                return {
                    title: 'Recall Response',
                }
            }
            if(this.communicationType == 'PRODUCT DISRUPTION')
            {
                return {
                    title: 'Supply Advisory Response',
                }
            }
        },
        filteredItems() {
            return this.autocompleteItems.filter((i) => {
                return i.text.toLowerCase().indexOf(this.tagE.toLowerCase()) !== -1
            })
        },
        filteredItemsR() {
            return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
            })
        },
    },
    created() {
        this.getEmailList()
    },
    methods: {
        forceRerenderModel() {
            this.$refs.modal.show()
            this.bindResponseccEmails()
            this.bindResponseEmails()
            this.emailErrorE = ''
            this.emailError=''
            if(this.filesUpload.length > 0 && this.ccvalueE.length > 0)
            {
                if(this.item.affectedProduct == 'No')
                {
                    this.isSaveEnabled = true
                }
                else if(this.item.affectedProduct == 'Yes' && this.item.affectedProductValue != null)
                {
                    this.isSaveEnabled = true
                }
            }
            else
            {
                this.isSaveEnabled = false
            }
        },
        bindResponseccEmails()
        {
            this.valueTag=[]
            this.enableValueTag=[]
            this.originalValueTag=[]
            this.options=[]
            this.ccvalue=[]
            var supplierCcEmails=this.CcEmails?.supplierRepCCEmails
            this.defaultEmailList = this.CcEmails?.supplierRepCCEmails
            var additionalRepEmails = this.CcEmails?.additionalCcEmails
            this.combineEmailList = supplierCcEmails?.concat(additionalRepEmails)
            this.combineEmailList?.forEach(x => {
                if (!this.options.includes(x)) {
                    this.options.push(x)
                }
                if (!this.ccvalue?.includes(x)) {
                    this.ccvalue.push(x)
                }
            })
            supplierCcEmails.forEach(x =>
            {
                if (this.valueTag.filter(s=>s.text==x).length<=0) {
                    this.valueTag.push({ 'text': x,'classes': ['ti-tag-disable-close']})
                    this.originalValueTag.push({ 'text': x,'classes': ['ti-tag-disable-close'] })
                    this.disableEmailTagList = true
                }
            })
            additionalRepEmails?.forEach(x =>
            {
                if (this.valueTag.filter(s=>s.text==x).length<=0) {
                    this.enableValueTag.push({'text': x})
                    this.valueTag.push({'text': x})
                }
            })
        },
        bindResponseEmails()
        {
            this.valueTagE=[]
            this.ccvalueE=[]
            if(this.response_email != '' & this.response_email != null)
            {
                this.ccvalueE.push(this.response_email)
                if(!this.allowResponseEmail)
                {
                    this.valueTagE.push({ 'text': this.response_email, 'classes': ['ti-tag-disable-close'] })
                }
                else
                {
                    this.valueTagE.push({ 'text': this.response_email, 'tiClasses': ['ti-valid'] })
                }
            }
            else
            {
                this.ccvalueE = []
                this.valueTagE = []
            }
        },
        saveEmail(){
            this.$refs['observer'].validate().then(isValid => {
                if (isValid) {
                    var cnt = 0
                    for (let k = 0; k < this.ccvalue.length; k++) {
                        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        var b = re.test(String(this.ccvalue[k].trim()).toLowerCase())
                        this.removevalidation()
                        if (!b) {
                            this.setvalidation()
                            cnt = cnt + 1
                        }
                    }
                    this.filesUpload.forEach(file => {
                        file.isAddedBeforeSave = false
                    })
                    //Add duplicate email validation
                    if (this.ccvalue.length > 1) {
                        for (let i = 0; i < this.ccvalue.length; i++) {
                            for (var k = i + 1; k < this.ccvalue.length; k++) {
                                if (this.ccvalue[i].trim() === this.ccvalue[k].trim()) {
                                    this.setvalidation()
                                    return this.emailError = 'The email has been already used.'
                                }
                            }
                        }
                    }
                    if (this.ccvalue.length > 0) {
                        for (let k = 0; k < this.valueTag.length; k++) {
                            if (this.ccvalue.find(x=> x == this.valueTag[k].text)) {
                            }
                            else
                            {
                                this.setvalidation()
                                return this.emailError = 'The email has been already used.'
                            }
                        }
                    }
                    for (let k = 0; k < this.ccvalueE.length; k++) {
                        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        let b = re.test(String(this.ccvalueE[k].trim()).toLowerCase())
                        this.removeResponsevalidation()
                        if (!b) {
                            this.setResponsevalidation()
                            cnt = cnt + 1
                        }
                    }
                    //Add Single email validation
                    if (this.ccvalueE.length > 1) {
                        this.setResponsevalidation()
                        return this.emailErrorE = 'The email has been already used.'
                    }
                    if(cnt==0)
                    {
                        let formData = new FormData()
                        if (this.ccvalue.length > 0) {
                            for (let k = 0; k < this.ccvalue.length; k++) {
                                if(!this.defaultEmailList.find( x=> x == this.ccvalue[k]))
                                {
                                    formData.append('additionalCcEmails', this.ccvalue[k])
                                }
                            }
                        }
                        for (let i = 0; i < this.defaultEmailList.length; i++) {
                            formData.append('supplierRepCCEmails', this.defaultEmailList[i])
                        }
                        formData.append('affectedProduct', this.item.affectedProduct)
                        formData.append('affectedProductValue', this.item.affectedProductValue)
                        this.filesUpload.forEach((x, i) => {
                            if (!x.id) {
                                formData.append('document', x.file)
                            }
                            formData.append('documentInfo[' + i + '].id', x.id ? x.id : 0)
                        })
                        this.$emit('onCompleted',formData)
                    }
                    else
                    {
                        this._showToast('Invalid email address.', { variant: 'danger' })
                    }
                }
            })
        },
        changeRecallStatus() {
            this.$emit('onSubmit','yes')
            this.$refs.modal.hide()
        },
        handleFilesUpload() {
            let uploadedFiles = this.$refs.files.files
            for ( let i = 0; i < uploadedFiles.length; i++ ) {
                if(uploadedFiles[i].size == 0){
                    this._showToast('Documented failed to upload. Please try again.', { variant: 'danger' })
                }
                else
                {
                    this.filesUpload.push({
                        file: uploadedFiles[i],
                        note: '',
                        is_thumbnail: false,
                        isAddedBeforeSave: true
                    })
                }
            }
            this.$refs.files.value = ''
        },
        removeDocument(index) {
            this.filesUpload.splice(index, 1)
            this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)

        },
        addResponseCheck(val) {
            if (val.length > 1) {
                this._showToast('Only one response email allowed', { variant: 'warning' })
                val.pop()
            }
            else
            {
                if (val.length > 0) {
                    this.ccvalueE = []
                    val.forEach(x => {
                        let IsVal = this.checkResponseEmailValidation(x.text)
                        if (!this.emailErrorE && IsVal) {
                            this.ccvalueE.push(x.text)
                        }
                        else
                        {
                            val.pop()
                        }
                    })
                }
                else {
                    this.removeResponsevalidation()
                    this.ccvalueE = []
                    this.domainError = ''
                }
            }
        },
        addCheck(val) {
            let isDuplicateEmail =false
            let tagArray=[]
            let emailArray=[]
            this.valueTag=[]
            let duplicateEmail=[]
            this.originalValueTag.forEach(x => {
                if(emailArray.includes(x.text))
                {
                    duplicateEmail.push(x.text)
                    isDuplicateEmail = true
                }
                emailArray.push(x.text)
                tagArray.push(x)
            })
            val.forEach(x => {
                if(x.tiClasses.includes('ti-duplicate'))
                {
                    duplicateEmail.push(x.text)
                    isDuplicateEmail = true
                }
                if (this.originalValueTag.find(y=>y.text==x.text) == null && !x.tiClasses.includes('ti-duplicate')) {
                    tagArray.push(x)
                }
            })
            val = []
            this.valueTag=tagArray
            val=tagArray
            if(isDuplicateEmail)
            {
                duplicateEmail.forEach(x => {
                    val.push(x)
                })
                this.setvalidation()
                this.emailError = 'The email has been already used.'
            }
            else
            {
                if (val.length > 0) {
                    this.ccvalue = []
                    val.forEach(x => {
                        this.checkEmailValidation(x.text)
                        if (!this.emailError) {
                            this.ccvalue.push(x.text)
                        }
                    })
                    if (this.ccvalue.length > 1) {
                        var valueOriginal = this.ccvalue.length
                        var valcurrent = val.length - 1
                        const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
                        var b = re.test(String(val[valcurrent].text.trim()).toLowerCase())
                        if (b) {
                            var i = 0
                            for (i = 0; i < valueOriginal - 1; i++) {
                                if (this.ccvalue[i].trim() === val[valcurrent].text.trim()) {
                                    this.setvalidation()
                                    this.emailError = 'The email has been already used.'
                                }
                            }
                        }
                    }
                }
                else {
                    this.removevalidation()
                    this.ccvalue = []
                    this.domainError = ''
                }
            }
        },
        checkResponseEmailValidation(email) {
            let Isval = true
            if (!email) {
                this.setResponsevalidation()
                this.emailErrorE = 'Email is required'
                Isval = false
            } else { this.emailErrorE = '' }
            if (email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                var b = re.test(String(email.trim()).toLowerCase())
                this.removeResponsevalidation()
                if (!b) {
                    this._showToast('Please enter valid email address', { variant: 'warning' })
                    this.setResponsevalidation()
                    Isval = false
                }
            }
            return Isval
        },
        checkEmailValidation(email) {
            if (!email) {
                this.setvalidation()
                this.emailError = 'Email is required'
            } else { this.emailError = '' }
            if (email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                var b = re.test(String(email.trim()).toLowerCase())
                this.removevalidation()
                if (!b) {
                    this._showToast('Please enter valid email address', { variant: 'warning' })
                    this.setvalidation()
                }
            }
        },
        setResponsevalidation(){
            var ele= this.$refs.response_emails.$el.getElementsByClassName('ti-input')
            ele[0].style='border-color:red !important'
        },
        removeResponsevalidation(){
            var ele= this.$refs.response_emails.$el.getElementsByClassName('ti-input')
            ele[0].style=' '
        },
        addResponseemailcolor() {
            var ele = this.$refs.response_emails.$el.getElementsByClassName('ti-input')
            ele[0].style='border-color:#5ec3ff !important'
        },
        setvalidation(){
            var ele= this.$refs.response_ccemails.$el.getElementsByClassName('ti-input')
            ele[0].style='border-color:red !important'
        },
        removevalidation(){
            var ele= this.$refs.response_ccemails.$el.getElementsByClassName('ti-input')
            ele[0].style=' '
        },
        addemailcolor() {
            var ele = this.$refs.response_ccemails.$el.getElementsByClassName('ti-input')
            ele[0].style='border-color:#5ec3ff !important'
        },
        isAffectedProduct() {
            if(this.item.affectedProduct == 'No')
            {
                this.isSaveEnabled = true
            }
            else
            {
                this.isSaveEnabled = false
            }
            this.item.affectedProductValue = ''
        },
        isAffectedProductValue() {
            if(this.item.affectedProduct != '')
            {
                if(this.item.affectedProduct == 'Yes')
                {
                    if(this.item.affectedProductValue != null)
                    {
                        this.isSaveEnabled = true
                    }
                    else
                    {
                        this.isSaveEnabled = false
                    }
                }
                else if(this.item.affectedProduct == 'No')
                {
                    this.isSaveEnabled = true
                }
            }
            else
            {
                this.isSaveEnabled = false
            }
        },
        getEmailList() {
            recallServicesProv
                .GetEmailList(this.$store.state.orgId)
                .then((resp) => {
                    if (!resp.error) {
                        resp.data.d.forEach((val) => {
                            this.autocompleteItems.push({
                                text: val.email
                            })
                        })
                    }
                })
        },
    }
}